export enum SVGFileEnum {
    ARROW_LEFT = 'arrow-left',
    ARROW_RIGHT = 'arrow-right',
    CHEVRON_RIGHT = 'chevron-right',
    CONSTRUCTION = 'construction',
    DANGER = 'danger',
    ENVELOPE = 'envelope',
    EXCLAMATION_CIRCLE = 'exclamation-circle',
    EXTERNAL_LINK = 'external-link',
    FILE = 'file',
    FILTER_SOLID = 'filter-solid',
    FILTER = 'filter',
    INVALID_CIRCLE = 'invalid-circle',
    LIST_CHECK = 'list-check',
    LIST_CROSS = 'list-cross',
    LIST_MINUS = 'list-minus',
    LOGIN = 'login',
    MOVE_HORIZONTAL = 'move-horizontal',
    PENCIL = 'pencil',
    PLUS = 'plus',
    QUESTION_CIRCLE = 'question-circle',
    RATING_INACTIVE = 'rating-inactive',
    RATING_MINUS = 'rating-minus',
    RATING_NOT_COMPLETE = 'rating-not-complete',
    RATING_NOT_PASSED = 'rating-not-passed',
    RATING_OPEN = 'rating-open',
    RATING_PASSED = 'rating-passed',
    SAVE = 'save',
    SEARCH = 'search',
    SPINNER = 'spinner',
    TRASH = 'trash',
    UNLINK = 'unlink',
    VALID_CIRCLE = 'valid-circle',
}

/* eslint-disable unicorn/filename-case */
import { SVGFileEnum } from '~/enums/SvgFileEnum';

const properties = {
    file: Object.values(SVGFileEnum),
    size: ['small', 'normal', 'large'],
    tag: ['span', 'a', 'button'],
    type: [null, 'button', 'submit'],
    variant: [null, 'secondary', 'yellow', 'green', 'red'],
};

export { properties };

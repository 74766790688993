/* eslint-disable unicorn/filename-case */
import { properties as iconProperties } from '../icon/-IconEnum';
import { SVGFileEnum } from '~/enums/SvgFileEnum';

const properties = {
    file: Object.values(SVGFileEnum),
    iconSize: iconProperties.size,
    tag: ['div'],
};

export { properties };
